.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.dish-title {
  display: flex;
  justify-content: center;
  min-height: 50px;
}

.image-side {
  margin-bottom: 20px;
}

.image {
  max-width: 100%;
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

.image:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.info-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.section-title {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px;
}

.content {
  margin-top: 0;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 20px;
  transition: background-color 0.2s ease-in-out;
}

.content:hover {
  background-color: #e0e0e0;
  color: #000;
}

.price {
  font-size: 24px;
  font-weight: bold;
  color: #f44336;
  margin-top: 5px;
}

.restaurant {
  margin-top: 20px;
}

.address {
  margin-top: 5px;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.loading {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 40px;
}

.pro-restaurants{
  display: flex;
  flex-direction: column;
  font-size: 1.5rem;
  font-weight: bold;
  align-items: center;
}

.rest-of-menu{
  font-size: 1.2rem;
  font-weight:lighter;
  margin-top: 0;
  margin-bottom: 5px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 20px;
  transition: background-color 0.2s ease-in-out;
  text-decoration: underline;
  color: black;
}
  
@media only screen and (max-height: 640px) {
  .dish-title {
    margin-top: 0;
  }
}

@media only screen and (min-width: 768px) {

  h2{
    font-size: 3rem;
  }

  .wrapper {
    flex-direction: row;
    align-items: center;
    margin: 40px;
  }

  .image-side {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    margin-right: 40px;
    width: 50%;
  }

  .info-side {
    width: 50%;
  }

  .section-title {
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 10px;
  }

  .content {
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 1rem;
  }

  .price {
    font-size: 32px;
    margin-top: 20px;
  }

  .restaurant {
    margin-top: 30px;
    font-size: 20px;
  }

  .address {
    margin-top: 10px;
    font-size: 16px;
    cursor: pointer;
  }

  button {
    margin-top: 30px;
    padding: 15px 30px;
    font-size: 20px;
  }

  .loading {
    font-size: 32px;
    margin-top: 60px;
  }

  .dish-title{
    display: flex;
    justify-content: center;
  }
}

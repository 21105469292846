.logo-wrapper{
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.logo{
  width: 50%;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  height: 70vh;
}

h1 {
  text-align: center;
  margin-top: 40px;
}

label {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px;
}

/* Add margin to the bottom of each input/select element */
input,
select {
  margin-top: 0;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 20px;
  border: none;
  transition: background-color 0.2s ease-in-out;
}

input:focus,
select:focus {
  outline: none;
  background-color: #e0e0e0;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

input {
  background-color: #e0e0e0;
  margin-left: 10px;
  width: 5rem;
}

select {
  background-color: #e0e0e0;
  margin-left: 10px;

}

.locate{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90%;
}

.min-max-together{
  display: flex;
}

.budget-wrapper{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 1.2rem;
}

.budget-text{
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 5px;
  margin-bottom: 1rem;
}

.flavor-section{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.meal-wrapper{
  margin-top: 1rem;
}

.flavor-options{
  display: flex;
  justify-content: space-evenly;
  width: 110%;
  margin-bottom: 1rem;
}

.flavor{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border: 1px solid;
  border-radius: 20px;
  background-color: #e0e0e0;
}

.only-option{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}


/* For screens larger than 768px */
@media screen and (min-width: 768px) {
  .logo-wrapper {
    justify-content: center;
  }

  .logo {
    height: 9rem;
    width: auto;
  }

  form {
    width: 50%;
    margin: auto;
  }

  h1 {
    font-size: 2.5rem;
  }

  label {
    font-size: 1.2rem;
  }

  input,
  select {
    font-size: 1.2rem;
  }

  button {
    font-size: 1.2rem;
  }
}
/* For screens smaller than 768px */
@media screen and (max-width: 767px) {
  .logo {
  height: 5rem;
  width: auto;
  }
  
  form {
  height: auto;
  }
  
  label {
  font-size: 1rem;
  }
  
  input{
    width: 3.5rem;
    font-size: 1rem;
  }  
  
  select {
  font-size: 1rem;
  margin-bottom: 10px;
  }
  
  #minBudget {
  margin-right: 5px;
  }
  
  button {
  font-size: 1rem;
  width: 100%;
  }
  
  .advanced-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 1rem;
  }
  
  .advanced-options-toggle {
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 30px;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  }
  
  .advanced-options-toggle:hover {
  background-color: #fff;
  color: #f44336;
  border: 2px solid #f44336;
  }
  
  .advanced-options-content {
  display: none;
  width: 100%;
  margin-top: 20px;
  }
  
  .advanced-options-content-show {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .arrow-down {
  display: none;
  }
  
  .advanced-options-toggle-show {
  background-color: #fff;
  color: #f44336;
  border: 2px solid #f44336;
  }
  }